@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



#home {
  margin: 0;
  min-height: 80vh;
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;
}

#home h1 {
  font-size: clamp(1rem, 3vw + 1rem, 4rem);
  position: relative;
  font-family: "Source Code Pro", monospace;
  width: -webkit-max-content;
  width: max-content;
}

#home h1::before,
#home h1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#home h1::before {
  background: white;
  -webkit-animation: typewriter 5s steps(25) 1s forwards;
          animation: typewriter 5s steps(25) 1s forwards;
}

#home h1::after {
  width: 0.125em;
  background: black;
  -webkit-animation: typewriter 5s steps(25) 1s forwards, blink 800ms steps(25) infinite;
          animation: typewriter 5s steps(25) 1s forwards, blink 800ms steps(25) infinite;
}

@-webkit-keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@-webkit-keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

#home p {
  margin: 1em;
  font-size: clamp(0.75rem, 2vw + 0.5rem, 3rem);
  opacity: 0;
  -webkit-transform: translateY(3rem);
          transform: translateY(3rem);
  -webkit-animation: fadeInUp 2s ease 6s forwards;
          animation: fadeInUp 2s ease 6s forwards;
}

@-webkit-keyframes fadeInUp {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

