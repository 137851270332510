@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap");

#home {
  margin: 0;
  min-height: 80vh;
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;
}

#home h1 {
  font-size: clamp(1rem, 3vw + 1rem, 4rem);
  position: relative;
  font-family: "Source Code Pro", monospace;
  width: max-content;
}

#home h1::before,
#home h1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#home h1::before {
  background: white;
  animation: typewriter 5s steps(25) 1s forwards;
}

#home h1::after {
  width: 0.125em;
  background: black;
  animation: typewriter 5s steps(25) 1s forwards, blink 800ms steps(25) infinite;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

#home p {
  margin: 1em;
  font-size: clamp(0.75rem, 2vw + 0.5rem, 3rem);
  opacity: 0;
  transform: translateY(3rem);
  animation: fadeInUp 2s ease 6s forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
